<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  @media (max-width: 1024px) {
    .sm-spacer {
      margin-top: .5rem;
    }
  }
  .save-btn.btn-success {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #34c38f;
    }

    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import {Game, OmegaAction, StreamEvent, TriggerAttributes, TriggerCondition} from "@/enums";

const humanizeDuration = require("humanize-duration");

export default {
  props: ['server_id', 'options'],
  components: {
    VclCode,
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    Multiselect
  },
  computed: {
    ...mapGetters([
      'getServer'
    ]),
  },
  methods: {
    // Source: https://gist.github.com/jed/982883
    uuid(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,this.uuid)},
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getConfiguration: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/scheduler`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.configuration = data.configuration;
          this.limit = data.limit;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    /* Support features per game */
    supportsKillfeed() {
      // Supports modding or has native method of kill transmission
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsWhitelist() {
      // Supports either native whitelist or emulated whitelist by kicking
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsReservedSlots() {
      // Either native or emulated reserved slot support by kicking
      let games = [];
      return games.includes(this.options.game);
    },
    supportsIPFeatures() {
      // Games that allow the retrieval of plain text player IP
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsSteam() {
      // Steam auth based games
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsGameIntegration() {
      // Steam auth based games
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    /* ************************** */
    /* Special features for actions */

    actionToString(action) {
      switch(action) {
        case OmegaAction.HELP: return this.$t('omega.actions.help');
        case OmegaAction.RAW: return this.$t('omega.actions.raw');
        case OmegaAction.MESSAGE_PRIVATE: return this.$t('omega.actions.message_private');
        case OmegaAction.MESSAGE_PUBLIC: return this.$t('omega.actions.message_public');
        case OmegaAction.SERVER_LOCK: return this.$t('omega.actions.server_lock');
        case OmegaAction.SERVER_UNLOCK: return this.$t('omega.actions.server_unlock');
        case OmegaAction.SERVER_RESTART: return this.$t('omega.actions.server_restart');
        case OmegaAction.SERVER_KICK: return this.$t('omega.actions.server_kick');
        case OmegaAction.SERVER_WIPE: return this.$t('omega.actions.server_wipe');
        case OmegaAction.WHITELIST_PAUSE: return this.$t('omega.actions.whitelist_pause');
        case OmegaAction.WHITELIST_RESUME: return this.$t('omega.actions.whitelist_resume');
        case OmegaAction.GAME_KILL: return this.$t('omega.actions.game_kill');
      }
      return `ERR (${action})`;
    },
    actionHasParameter(action) {
      let unParameterizedActions = [
        OmegaAction.HELP,
        OmegaAction.GAME_KILL,
        OmegaAction.WHITELIST_PAUSE,
        OmegaAction.WHITELIST_RESUME,
        OmegaAction.SERVER_LOCK,
        OmegaAction.SERVER_UNLOCK,
          OmegaAction.SERVER_RESTART, // Only unparameterized for Scheduler
      ];
      return !(unParameterizedActions.includes(action));
    },
    // https://stackoverflow.com/questions/1267283/how-can-i-pad-a-value-with-leading-zeros
    zeroFill(number, width) {
      width -= number.toString().length;
      if ( width > 0 )
      {
        return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
      }
      return number + ""; // always return a string
    },
    validateJob(job) {
      if(job.action === null) return false;
      if(job.relative === true) {
        if(job.minutes === null || job.minutes <= 0) return false;
        else return true;
      } else {
        if(job.days.length === 0) return false;
        else if(job.minute === null || job.hour === null) return false;
        else return true;
      }
    },
    getJobTimeString(job) {
      if(job.relative === true) {
        return this.$t('server.view.jobs.string.relative', {minutes: job.minutes});
      } else {
        if(job.days.length === 7) {
          return this.$t('server.view.jobs.string.daily', {hour: this.zeroFill(job.hour, 2), minute: this.zeroFill(job.minute, 2)});
        } else {
          let days = [];
          job.days.forEach((dayIndex) => {
            days.push(this.$t(`days.${dayIndex}`));
          });
          return this.$t('server.view.jobs.string.days', {days: days.join(', '), hour: this.zeroFill(job.hour, 2), minute: this.zeroFill(job.minute, 2)});
        }
      }
    },
    humanizeDuration(duration) {
      return humanizeDuration(duration * 1000 * 60);
    },
    /* ************************** */
    async saveConfiguration() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let jobs = [];
        /* Integrity check */
        this.configuration.jobs.forEach((job) => {
          if(!this.validateJob(job)) return;
          else jobs.push(job)
        });
        this.configuration.jobs = jobs;
        /* *************** */
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          configuration: this.configuration
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/scheduler`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.configuration.save.success.title'),
            text: this.$t('server.view.configuration.save.success.text'),
          });
          this.configurationChanged = null;
          await this.getConfiguration();
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    },
    /* Processing */
    async addElement() {
      this.configuration.jobs.push({
        action: null,
        parameters: {
          message: '',
          lock: {
            status: true,
            lead: 2
          },
          kick: {
            status: true,
            lead: 1
          }
        },
        relative: false,
        days: [],
        hour: 0,
        minute: 0,
        minutes: 0,
        precursor: [],
        uuid: this.uuid()
      });
    },
    async removeElement(uuid) {
      let elements = [];
      this.configuration.jobs.forEach((el) => {
        if(el.uuid !== uuid) elements.push(el);
      });
      this.configuration.jobs = [];
      this.configuration.jobs = elements;
    },
    async onActionChange(job) {
      if(job.action === OmegaAction.SERVER_RESTART) {
        job.precursor = [60, 30, 15, 10, 5, 3, 2, 1];
      } else job.precursor = [];
    },
    /* ***** */
	  scrollHandler() {
		  if(!this.$refs.floatingContainer) return;

		  if(window.pageYOffset > 70) {
			  if(!this.$refs.floatingContainer.classList.contains('c-floating-side')) {
				  this.$refs.floatingContainer.classList.add('c-floating-side');
			  }
		  } else {
			  this.$refs.floatingContainer.classList.remove('c-floating-side');
		  }
	  }
  },
  created() {
    this.server = this.getServer(this.server_id);
  },
  mounted() {
    this.getConfiguration();

	  window.addEventListener('scroll', this.scrollHandler);
  },
	destroyed() {
		window.removeEventListener('scroll', this.scrollHandler);
	},
  watch: {
    configuration: {
      deep: true,
      handler() {
        if(!this.ready) return;
        if(this.configurationChanged === null) {
          this.configurationChanged = false;
        } else this.configurationChanged = true;
      }
    }
  },
  data() {
    return {
      Game:Game,
      OmegaAction:OmegaAction,
      ready: false,
      error: false,
      inProgress: false,
      server: null,
      configurationChanged: null,
      configuration: {

      },
      limit: null,
      availableActions: [
        OmegaAction.RAW,
        OmegaAction.MESSAGE_PRIVATE,
        OmegaAction.MESSAGE_PUBLIC,
        OmegaAction.SERVER_LOCK,
        OmegaAction.SERVER_UNLOCK,
        OmegaAction.SERVER_RESTART,
        //OmegaAction.SERVER_KICK,
        OmegaAction.SERVER_WIPE,
        OmegaAction.WHITELIST_PAUSE,
        OmegaAction.WHITELIST_RESUME
      ],
      availableDays: [
          0,
          1,
          2,
          3,
          4,
          5,
          6
      ],
      availablePrecursor: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
      // Special configs
      special: {

      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-sm-12 d-lg-none">
          <button class="btn btn-block hard-border save-btn"
                  v-on:click="saveConfiguration()"
                  :disabled="inProgress"
                  :class="{
                    'disabled': inProgress,
                    'btn-success': configurationChanged === true,
                    'btn-primary': configurationChanged === false
                  }"
          >
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.view.configuration.save.button') }}
            </template>
          </button>
          <div class="text-center small text-muted" v-if="configurationChanged === true">
            {{ $t('server.view.configuration.save.info') }}
          </div>
        </div>
        <div class="col-sm-12 mt-2 d-lg-none">
          <template v-if="!limit || configuration.jobs.length < limit">
            <button class="btn btn-info btn-block hard-border" v-on:click="addElement()">
              <i class="far fa-plus" />
              {{ $t('server.view.jobs.button') }}
            </button>
          </template>
          <template v-if="limit">
            <div class="card text-center mb-0">
              <div class="card-body" v-if="configuration.jobs.length >= limit">
                <h5 class="text-danger">
                  <i class="fad fa-times-hexagon text-danger"/>
                  {{$t('server.view.jobs.limits.reached')}}
                </h5>
                <span class="h5 text-danger">{{configuration.jobs.length}} / {{limit}}</span>
              </div>
              <div class="card-body" v-else>
                <h5>
                  {{$t('server.view.jobs.limits.info')}}
                </h5>
                <span class="h5">{{configuration.jobs.length}} / {{limit}}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="col-sm-12 mt-2 d-lg-none">
          <div class="card hard-border ">
            <div class="card-body">
              <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
              {{$t('server.view.configuration.placeholders.message')}}
              <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
                <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-sm-12">
          <div>
            <div role="tablist">
              <template v-if="configuration.jobs.length === 0">
                <div class="row">
                  <div class="col">
                    <h4 class="text-uppercase text-center text-muted mt-4">
                      {{ $t('server.view.jobs.none') }}
                    </h4>
                  </div>
                </div>
              </template>
              <b-card no-body class="settings-collapse hard-border" v-for="job in configuration.jobs" :key="job.uuid">
                <a v-b-toggle="job.uuid" class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      <template v-if="validateJob(job)">
                        {{ actionToString(job.action) }}
                        <small class="text-muted font-size-14 mt-2 ml-1 hide-sm">
                          {{ getJobTimeString(job) }}
                        </small>
                      </template>
                      <template v-else>
                        <div class="badge badge-warning text-black">
                          {{ $t('server.view.jobs.unconfigured') }}
                        </div>
                      </template>
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.configuration.expand') }}
                      </div>
                    </div>
                    <div class="d-inline-flex float-right mr-2">
                      <div class="btn btn-sm btn-danger" v-on:click="removeElement(job.uuid)">
                        <i class="fal fa-trash-alt" />
                        {{ $t('server.view.configuration.remove') }}
                      </div>
                    </div>
                    <div class="d-inline-flex float-right mr-2">
                      <toggle-button
                          v-model="job.status"
                         :sync="true"
                         :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                         :height="25"
                         :width="60"
                         :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                         style="font-size: 12px !important;"
                         class="m-0 mb-1"
                      />
                    </div>
                    <div class="small text-muted font-size-13 mt-2 d-lg-none">
                      {{ getJobTimeString(job) }}
                    </div>
                  </b-card-header>
                </a>
                <b-collapse :id="job.uuid" accordion="jobs" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-lg-6 col-sm-12">
                          <h4>{{ $t('server.view.jobs.time.label') }}</h4>
                          <hr>
                          <div class="row">
                            <div class="col-lg-6 col-sm-12 text-center">
                              <toggle-button
                                  v-model="job.relative"
                                 :sync="true"
                                 :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                 :height="30"
                                 :width="70"
                                 :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                 style="font-size: 13px !important;"
                                 class="m-0 mt-1 mb-1"
                              />
                              <div>
                                {{ $t('server.view.jobs.time.relative.label') }}
                              </div>
                            </div>
                            <div class="col-lg-6 col-sm-12 sm-spacer">
                              <template v-if="job.relative">
                                <div class="row">
                                  <div class="col">
                                    <b-input v-model="job.minutes" min="0" max="1439" @change="job.minutes = Math.max(Math.min(job.minutes, 1439), 0);" size="lg" :placeholder="$t('server.view.jobs.time.relative.placeholder')" type="number" class="form-control" />
                                    <div class="text-center">
                                      {{ $t('server.view.jobs.time.relative.description') }}
                                      ({{ humanizeDuration(job.minutes) }})
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <div class="row">
                                  <div class="col">
                                    <b-input v-model="job.hour" min="0" max="24" @change="job.hour = Math.max(Math.min(job.hour, 24), 0);" size="lg" placeholder="HH" type="number" class="form-control" />
                                  </div>
                                  <div class="mt-2 font-size-18 text-white">
                                    <b>:</b>
                                  </div>
                                  <div class="col">
                                    <b-input v-model="job.minute" min="0" max="59" @change="job.minute = Math.max(Math.min(job.minute, 59), 0);" size="lg" placeholder="MM" type="number" class="form-control" />
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 sm-spacer">
                          <h4>{{ $t('server.view.jobs.days.label') }}</h4>
                          <hr>
                          <div class="row">
                            <div class="col-lg-12 col-sm-12">
                              <multiselect
                                  v-model="job.days"
                                  :options="availableDays"
                                  :multiple="true"
                                  :close-on-select="false"
                                  :clear-on-select="false"
                                  :preserve-search="true"
                                  :placeholder="$t('server.view.jobs.days.placeholder')"
                                  :preselect-first="false"
                                  :taggable="false"
                              >
                                <template slot="tag" slot-scope="{ option, remove }">
                                  <span class="multiselect__tag ">
                                    <span>{{ $t(`days.${option}`) }}</span>
                                    <i class="multiselect__tag-icon" @click="remove(option)" />
                                  </span>
                                </template>
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                                  <div>
                                    <span class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ $t(`days.${props.option}`) }}
                                      </span>
                                    </span>
                                  </div>
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="props"
                                >
                                  <div>
                                    <div class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ $t(`days.${props.option}`) }}
                                      </span>
                                    </div>
                                  </div>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-6 col-sm-12 sm-spacer">
                          <h4>{{ $t('server.view.jobs.action.label') }}</h4>
                          <hr>
                          <div class="row">
                            <div class="col-lg-12 col-sm-12">
                              <multiselect
                                  v-model="job.action"
                                  :options="availableActions"
                                  :multiple="false"
                                  :close-on-select="true"
                                  :clear-on-select="false"
                                  :preserve-search="true"
                                  :placeholder="$t('server.view.jobs.action.placeholder')"
                                  :preselect-first="false"
                                  :taggable="false"
                                  @input="onActionChange(job)"
                              >
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                                  <div>
                                    <span class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ actionToString(props.option) }}
                                      </span>
                                    </span>
                                  </div>
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="props"
                                >
                                  <div>
                                    <div class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ actionToString(props.option) }}
                                      </span>
                                    </div>
                                  </div>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="job.action && actionHasParameter(job.action)">
                        <div class="row mt-3">
                          <div class="col-lg-12 col-sm-12">
                            <h4>{{ $t('server.view.jobs.parameter.label') }}</h4>
                            <hr>
                            <div class="row">
                              <div class="col">
                                <b-input v-model="job.parameter" size="lg" type="text" class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="job.action === OmegaAction.SERVER_RESTART">
                        <div class="row mt-2">
                          <div class="col-lg-4 col-sm-12">
                            <div class="row">
                              <div class="col-lg-6 col-sm-12 text-center">
                                <toggle-button
                                    v-model="job.parameters.kick.status"
                                    :sync="true"
                                    :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                    :height="30"
                                    :width="70"
                                    :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                    style="font-size: 13px !important;"
                                    class="m-0 mt-1 mb-1"
                                />
                                <div>
                                  {{ $t('server.view.jobs.kick.label') }}
                                </div>
                              </div>
                              <div class="col-lg-6 col-sm-12 sm-spacer">
                                <div class="row">
                                  <div class="col">
                                    <b-input
		                                    v-model="job.parameters.kick.lead"
		                                    size="lg"
		                                    :placeholder="$t('server.view.jobs.kick.placeholder')"
		                                    type="number"
		                                    class="form-control"
		                                    min="0"
		                                    max="60"
		                                    @change="job.parameters.kick.lead = Math.max(Math.min(job.parameters.kick.lead, 86400), 0);"
                                    />
                                    <div class="text-center">
                                      {{ $t('server.view.jobs.kick.description') }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-12">
                            <div class="row">
                              <div class="col-lg-6 col-sm-12 text-center">
                                <toggle-button
                                    v-model="job.parameters.lock.status"
                                    :sync="true"
                                    :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                    :height="30"
                                    :width="70"
                                    :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                    style="font-size: 13px !important;"
                                    class="m-0 mt-1 mb-1"
                                />
                                <div>
                                  {{ $t('server.view.jobs.lock.label') }}
                                </div>
                              </div>
                              <div class="col-lg-6 col-sm-12 sm-spacer">
                                <div class="row">
                                  <div class="col">
                                    <b-input
		                                    v-model="job.parameters.lock.lead"
		                                    size="lg"
		                                    :placeholder="$t('server.view.jobs.lock.placeholder')"
		                                    type="number"
		                                    class="form-control"
		                                    min="0"
		                                    max="60"
		                                    @change="job.parameters.lock.lead = Math.max(Math.min(job.parameters.lock.lead, 86400), 0);" />
                                    <div class="text-center">
                                      {{ $t('server.view.jobs.lock.description') }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-12">
                            <multiselect
                                v-model="job.precursor"
                                :options="availablePrecursor"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                :placeholder="$t('server.view.jobs.precursor.placeholder')"
                                :preselect-first="false"
                                :taggable="false"
                            >
                              <template
                                  slot="singleLabel"
                                  slot-scope="props"
                              >
                                <div>
                                    <span class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ props.option }} {{ $t('generic.minutes')}}
                                      </span>
                                    </span>
                                </div>
                              </template>
                              <template
                                  slot="option"
                                  slot-scope="props"
                              >
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ props.option }} {{ $t('generic.minutes')}}
                                      </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                            <div class="text-center">
                              {{ $t('server.view.jobs.precursor.description') }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <!---------------------------------------->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-12 hide-sm">
	        <div class="" ref="floatingContainer">
	          <button class="btn btn-block hard-border save-btn"
	                  v-on:click="saveConfiguration()"
	                  :disabled="inProgress"
	                  :class="{
	                    'disabled': inProgress,
	                    'btn-success': configurationChanged === true,
	                    'btn-primary': configurationChanged === false
	                  }"
	          >
	            <half-circle-spinner
	                v-if="inProgress"
	                :animation-duration="1200"
	                :size="16"
	                class="align-middle d-inline-block"
	            />
	            <template v-if="!inProgress">
	              {{ $t('server.view.configuration.save.button') }}
	            </template>
	          </button>
	          <div class="text-center small text-muted" v-if="configurationChanged === true">
	            {{ $t('server.view.configuration.save.info') }}
	          </div>

	          <template v-if="!limit || configuration.jobs.length < limit">
	            <button class="btn btn-info btn-block hard-border mt-2 mb-2" v-on:click="addElement()">
	              <i class="far fa-plus" />
	              {{ $t('server.view.jobs.button') }}
	            </button>
	          </template>
	          <template v-if="limit">
	            <div class="card text-center mb-0">
	              <div class="card-body" v-if="configuration.jobs.length >= limit">
	                <h5 class="text-danger">
	                  <i class="fad fa-times-hexagon text-danger"/>
	                  {{$t('server.view.jobs.limits.reached')}}
	                </h5>
	                <span class="h5 text-danger">{{configuration.jobs.length}} / {{limit}}</span>
	              </div>
	              <div class="card-body" v-else>
	                <h5>
	                  {{$t('server.view.jobs.limits.info')}}
	                </h5>
	                <span class="h5">{{configuration.jobs.length}} / {{limit}}</span>
	              </div>
	            </div>
	          </template>

	          <div class="row mt-2">
	            <div class="col-lg-12">
	              <div class="card">
	                <div class="card-body">
	                  <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
	                  {{$t('server.view.configuration.placeholders.message')}}
	                  <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
	                    <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
	                  </a>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
